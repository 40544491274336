<template>
  <v-container fill-height class="justify-center">
    <v-row class="mb-10" justify="center">
      <v-col cols="7" md="6" lg="5" xl="4">
        <v-card
          class="pa-8"
          height="100%"
          width="100%"
          flat
          :disabled="disableCard"
          :elevation="3"
          @click="handleCaptcha()"
        >
          <v-row class="mb-2" justify="center">
            <v-col cols="8">
              <v-img :src="require('@/assets/new_figure.png')" />
            </v-col>
          </v-row>

          <div class="text-h6 font-weight-light text-center">
            Iniciar Novo Atendimento
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8" xl="7">
        <div class="text-body-1 font-weight-light text-center">
          Use a opção acima para iniciar um novo atendimento. Este canal foi
          criado para facilitar o contato direto entre cidadãos, advogados(as) e
          o Tribunal de Justiça do Estado do Amazonas.
        </div>
      </v-col>
    </v-row>

    <!-- O Overlay de loading -->
    <LoadingOverlay :loading="loading.overlay" />

    <!-- Dialog de Captcha -->
    <v-dialog v-model="captchaDialog" max-width="19.75rem" persistent>
      <v-card v-if="captchaDialog" class="pa-2">
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="_siteKey"
          @verify="onVerify"
          @expired="onExpired"
        ></vue-recaptcha>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { handleAlert, formatDocument, showError } from "@/utils";
import LoadingOverlay from "@/components/loadingOverlay/LoadingOverlay.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Home",

  components: {
    LoadingOverlay,
    VueRecaptcha,
  },

  data() {
    return {
      disableCard: false,
      showForm: false,
      dialog: false,
      captchaDialog: false,
      loading: {
        overlay: false,
        unities: false,
      },
      dialogTitle: "Escolha abaixo a instância e o balcão de atendimento",
      person: {
        _id: "65e8d608f975c49e4a156051",
        name: "Solicitante",
        cpf: "000.000.000-00",
        email: "solicitante@tj-am.jus.br",
        phone: "11999999999",
      },
    };
  },

  computed: {
    ...mapState("socket", ["attendance"]),

    _siteKey() {
      return process.env.VUE_APP_GCAPTCHA_PUBLIC_KEY;
    },
  },

  mounted() {
    this.handleQuerys();
  },

  methods: {
    ...mapActions("socket", ["createNewAttendance", "setAttendance"]),
    ...mapActions(["setPreSelection"]),

    handleCaptcha() {
      this.captchaDialog = true;
    },

    async handleStartChat() {
      console.log("attendance:new");

      await this.createNewAttendance({
        callback: (error, data) => {
          console.log("createNewAttendance:data", data);

          if (error) {
            console.log("createNewAttendance:error", error);
            throw new Error("Falha ao tentar conectar ao socket. error");
          }

          this.setAttendance(data);

          this.$router.push("/chat-bot").catch(() => {});
        },
      });
    },

    onVerify(response) {
      if (!response) return (this.captchaDialog = false);

      this.handleStartChat();
    },

    onExpired() {
      this.captchaDialog = false;
    },

    async handleQuerys() {
      const query = this.$route.query;

      if (!query || !Object.keys(query).length) return;

      this.loading.overlay = true;

      const payload = {
        ...(query.deskID && { deskID: query.deskID }),
        ...(query.subgroupID && { subgroupID: query.subgroupID }),
        ...(query.groupID && { groupID: query.groupID }),
      };

      await this.setPreSelection(payload);

      if (Object.keys(payload).length) await this.handleStartChat();

      this.loading.overlay = false;
    },

    showError,

    handleAlert,

    formatDocument,
  },
};
</script>

<style src="./style.scss" lang="scss" />
